.ribbon-wrapper {
    position: relative;
    z-index: 1;
    display: inline-block;
  }
  .ribbon {
    position: relative;
    background: #8e6d96;
    color: #fff;
    padding: .3em 2em;
  }
  
  .ribbon:after,
  .ribbon:before {
    content: "";
    position: absolute;
    display: block;
    bottom: -1em;
    border: .8em solid #5e4d72;
    z-index: -1
  }
  
  .ribbon:before {
    left: -1.3em;
    border-right-width: 1.5em;
    border-left-color: transparent
  }
  
  .ribbon:after {
    right: -1.3em;
    border-left-width: 1.5em;
    border-right-color: transparent
  }
  
  .ribbon .ribbon-inner:after,
  .ribbon .ribbon-inner:before {
    content: "";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #332f4e transparent transparent;
    bottom: -1em
  }
  
  .ribbon .ribbon-inner:before {
    left: 0;
    border-width: 1em 0 0 1em
  }
  
  .ribbon .ribbon-inner:after {
    right: 0;
    border-width: 1em 1em 0 0
  }