@import "styles/custom.scss";

body {
  background-color: #0b132b;
  color: #9fffe9;
}

.card-link {
  text-decoration: "none";
}

.non-card-link {
  text-decoration: "underline";
}

.card-main {
  border-style: solid;
  border-width: 10px;
  border-color: #9FFFE9;
}

.card-hero {
  background-color: #fff8ec;
  border-radius: 0%;
  width: 85%;
  border-style: solid;
  border-width: 5px;
  border-color: #9FFFE9;
}

.emoji-enlarged {
  font-size: 40px;
}

.badgeStyle {
  height: 40px;
}
.appStoreButton {
  text-align: center;
}

// 0B132B : Oxford Blue - darkest
// 1C2541 : Space Cadet
// 3A506B : Independence
// 5BC0BE : Maximum Blue Green
// 9FFFE9 : Turquoise Blue
